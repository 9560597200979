<template>
  <a-card title="统计查询">
    <div slot="extra">
      <a-button
        type="primary"
        @click="statistics"
      >查询</a-button>&nbsp;
      <a-button
        type="primary"
        @click="handleClickExportEcharts"
      >导出</a-button>
    </div>
    <ax-form
      ref="formBox"
      :formBuilder="formBuilder"
      style="padding:0px 10px"
    >
    </ax-form>
    <div
      id="myChart"
      :style="{ width: '100%', height: '600px',margin:'20px' }"
    ></div>
  </a-card>
</template>

<script>
import { initGridFormData } from '@/common/tools'
import api from './api'
export default {
  name: 'statistics',
  components: {
  },
  data () {
    return {
      api,
      title: '职务统计查询',
      formBuilder: initGridFormData([
        { label: '科室', type: 'select', model: 'deptIds', options: { mode: 'multiple', optionFilterProp: 'label', labelInValue: false }, col: { span: 12 } },
        { label: '分类', type: 'radio', model: 'type', options: { options: [{ label: '职务统计', value: '0' }, { label: '职称统计', value: '1' }, { label: '学历统计', value: '2' }, { label: '学位统计', value: '3' }, { label: '聘用方式', value: '4' }], defaultValue: '0' }, rules: [{ required: true, message: '工单等级不能为空' }], formItem: { colon: false }, col: { span: 12 } }
      ], { layout: 'horizontal', labelWidth: 120 }, { col: { span: 8 } })
    }
  },
  async created () {
  },
  mounted () {
    // 申请科室
    this.deptList()
    this.statistics()
  },
  methods: {
    handleClickExportEcharts () { // 导出Echart
      var canvas = document.getElementsByTagName('canvas')
      if (canvas && canvas.length > 0) {
        // 创建标签
        var oA = document.createElement('a')
        // 设置下载名称
        oA.download = '良好实践统计' + '.png'
        // 设置地址以及文件类型
        oA.href = canvas[0].toDataURL()
        document.body.appendChild(oA)
        // 触发下载事件
        oA.click()
      } else {
        this.$message.error('请先统计再导出')
      }
    },
    async deptList () {
      api.deptTreeList().then(res => {
        const options = res.data.map(res => { return { label: res.deptName, value: res.id } })
        this.$refs.formBox.setOptions(['deptIds'], 'options', options)
      })
    },
    checkType (val) {
      if (val === '0') {
        return '职务统计查询'
      } else if (val === '1') {
        return '职称统计查询'
      } else if (val === '2') {
        return '学历统计查询'
      } else if (val === '3') {
        return '学位统计查询'
      } else if (val === '4') {
        return '聘用方式查询'
      }
    },
    statistics () {
      api.statistics(this.$refs.formBox.getFieldsValue()).then(res => {
        this.title = this.checkType(this.$refs.formBox.getFieldsValue().type)
        const myChart = this.$echarts.init(document.getElementById('myChart'))
        myChart.setOption({
          title: {
            text: this.title,
            left: 'center',
            top: 'top',
            textStyle: {
              fontSize: 22
            }
          },
          xAxis: {
            type: 'category',
            data: res.data.xaxis,
            name: '分类',
            nameTextStyle: {
              fontSize: 16
            },
            axisLabel: {
              fontSize: 16
            }
          },
          yAxis: {
            name: '人数',
            type: 'value',
            nameTextStyle: {
              fontSize: 16
            },
            axisLabel: {
              fontSize: 16
            }
          },
          series: [{
            data: res.data.series,
            type: 'bar',
            barWidth: 40,
            itemStyle: {
              normal: {
                label: {
                  formatter: '{c}' + '人',
                  show: true,
                  position: 'top',
                  fontSize: 16
                }
              }
            }
          }]
        })
      })
    }
  }
}
</script>
